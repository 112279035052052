<template>
  <div class="container">
    <div class="breadcrumb">
      <p class="topic_path m-0">
        <router-link :to="{ name: 'AccountSettings'}" class="ashen-link">{{
            $t("account.default.pageTitle")
          }}
        </router-link>
        <i class="fas fa-angle-right form-control-color"></i> {{ $t("account.editWithPassword.changeFullName") }}
      </p>
    </div>
    <div class="card text-first border-0">
      <div class="card-body max-800 mx-auto col-12">
        <div class="row">
          <h3 class="fw-bold mb-4">{{ $t("account.editWithPassword.changeFullName") }}</h3>
          <div class="col">
            <label for="exampleInputPassword1">{{ $t("account.editWithPassword.authenticationPassword") }}</label>
            <input v-model="authenticationPassword" class="form-control" type="password">
            <div v-show="errors['authenticationPassword']" id="err_password" class="form-error">
              {{ errors['authenticationPassword'] }}
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col m-t-15">
            <label for="inputSaito">{{ $t("account.editWithPassword.labelLastName") }}</label>
            <input v-model="lastName" class="form-control" name="saito" type="text">
          </div>
          <div class="col m-t-15">
            <label for="inputMinatsu">{{ $t("account.editWithPassword.labelFirstName") }}</label>
            <input v-model="firstName" class="form-control" name="minatsu" type="text">
          </div>
        </div>
        <div class="row">
          <div v-show="errors['lastName']" id="err_lastName" class="form-error">{{ errors['lastName'] }}</div>
          <div v-show="errors['firstName']" id="err_firstName" class="form-error">{{ errors['firstName'] }}</div>
        </div>
        <div class="row m-t-20">
          <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 order-1 order-sm-0">
            <button class="btn btn-light bg-white w-100 mw-100 ashen-link text-decoration-none mt-2 light border-0 custom-btn"
                    @click="$router.push('account')">
              {{ $t("account.editWithPassword.btnCancel") }}
            </button>
          </div>
          <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 order-0 order-sm-1">
            <button class="btn btn-primary w-100 mw-100 ok btn-outline-light font-weight-bold mt-2 custom-btn"
                    v-on:click="doChange">
              {{ $t("account.editWithPassword.btnChange") }}
            </button>
          </div>
        </div>
        <!--                <div class="row mx-auto justify-content-between m-t-5">-->
        <!--                    <button class="btn btn-light bg-white ashen-link text-decoration-none mt-2 ml-2 btn-outline-light font-weight-bold mx-auto mt-sm-4 col-12 col-sm-6 order-1 order-sm-0 custom-btn" @click="$router.push('account')">{{ $t("account.editWithPassword.btnCancel") }}</button>-->
        <!--                    <button v-on:click="doChange" class="btn btn-primary ok btn-outline-light font-weight-bold mx-auto mt-4  col-12 col-sm-6 order-0 order-sm-1 custom-btn">{{ $t("account.editWithPassword.btnChange") }}</button>-->
        <!--                </div>-->
      </div>
    </div>
  </div>
</template>

<script>
import {SetUpdateUser} from "../assets/jsipclient/index";
import Common from "../assets/jsipclient/common";
import {mapGetters} from "vuex";

export default {
  name: "EditName",
  data() {
    return {
      lastName: '',
      firstName: '',
      authenticationPassword: '',
      errors: []
    }
  },
  computed: {
    ...mapGetters(['config']),
  },
  methods: {
    createSetUpdateUser() {
      var params = new SetUpdateUser()
      params.initialUpdate = false
      params.authenticationPassword = this.authenticationPassword
      params.firstName = this.firstName
      params.firstNameUpdate = true
      params.lastName = this.lastName
      params.lastNameUpdate = true
      params.password = ''
      params.passwordConfirm = ''
      params.passwordUpdate = false
      params.email = ''
      params.emailUpdate = false
      params.birthday = ''
      params.birthdayUpdate = false
      params.gender = 1;
      params.genderUpdate = false
      params.nationalityId = 1
      params.nationalityIdUpdate = false
      params.address = ''
      params.addressUpdate = false
      params.phoneNo = ''
      params.phoneNoUpdate = false
      params.hasCoronaVaccination = false
      params.latestCoronaVaccinationDate = ''
      params.coronaVaccinationUpdate = false
      params.latestPcrTestResult = 0
      params.latestPcrTestDate = ''
      params.latestPcrTestUpdate = false

      return params
    },
    async doChange() {
      this.errors = [];
      const user_id = this.config.userId
      const token = this.config.token
      try {
        const api = Common.createUserApi(token)
        const setUpdateUser = this.createSetUpdateUser()
        const jsonObject = await api.usersUserIdUpdatePostAsync(user_id, setUpdateUser, 0)

        const message = this.$t("account.editWithPassword.toastSuccessChangeFullName")
        Common.showToast(message)
        this.$router.push({name: 'AccountSettings'});
      } catch (error) {
        Common.getErrorList(error.response, this.errors)
        const isTokenError = Common.isTokenError(error.response)
        if (isTokenError) {
          this.$router.push({name: 'Login'});
        } else {
          Common.handleAPIError(error)
        }
      }
    },
    async getUser() {
      const user_id = this.config.userId
      const token = this.config.token
      try {
        const api = Common.createUserApi(token)
        const jsonObject = await api.usersUserIdGetAsync(user_id)

        this.firstName = jsonObject.firstName
        this.lastName = jsonObject.lastName
      } catch (error) {
        const isTokenError = Common.isTokenError(error.response)
        if (isTokenError) {
          this.$router.push({name: 'Login'});
        } else {
          Common.handleAPIError(error)
        }
      }
    },
  },
  mounted() {
    window.scrollTo({top: 0, behavior: "auto"})
    this.getUser()
  },
  watch: {
    async $route(to, from) {
      // Called from back or next
      await this.getUser()
    }
  }
}
</script>

<style scoped>

</style>
